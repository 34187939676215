
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { ElMessage } from 'element-plus'
@Options({
  data() {
    return {
      tableData: [],
      dataType: [
        { lable: '销售数据', value: 'xiao' },
        { lable: '采购数据', value: 'jin' },
        { lable: '库存数据', value: 'cun' }
      ],
      token: '',
      // baseUrl: 'http://localhost:3000',
      baseUrl: 'https://shuotengapi.qtdatas.com',
      type: '',
      batch: '',
      page: 1,
      limit: 10
    }
  },
  methods: {
    getItem(key: string) {
      const storageKey = 'qt_Token_key'
      if (!key) {
        return
      }
      const _storageKey = JSON.parse(
        window.localStorage.getItem(storageKey) || '{}'
      )
      return _storageKey[key] || ''
    },
    getData() {
      axios({
        url: `${this.baseUrl}/api/get/data`,
        method: 'get',
        headers: {
          'access-token': this.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
          type: this.type,
          page: this.page,
          limit: this.limit,
          batch: this.batch
        }
      }).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.tableData = res.data.data
        } else {
          ElMessage({
            type: 'warning',
            message: res.data.msg
          })
          // this.show = true
        }
      })
    },
    prevClick() {
      this.page = this.page - 1
      this.getData()
    },
    currentChange(page: number) {
      this.page = page
      this.findData()
    },
    nextClick() {
      this.page = this.page + 1
      this.getData()
    }
  },
  created() {
    const token = this.getItem('token')
    this.token = token.token
    // this.getData()
  }
})
export default class Data extends Vue {}
